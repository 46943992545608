export default [
  {
    header: 'Shop',
  },
  {
    title: 'Shop Uploads',
    route: 'shopuploads-list',
    icon: 'PaperclipIcon',
  },
]
