<template lang="">
  <b-container fluid>
    <b-row>
      <b-col>
        <b-button
          block
          style="margin: 1px 0px 0px 0px"
          variant="success"
          @click="createBooking"
        >
          Direktbuchung
        </b-button>
      </b-col>
      <b-col>
        <b-button
          block
          style="margin: 1px 0px 0px 0px"
          variant="success"
          @click="createOffer"
        >
          Angebotserstellung
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { BButton, BCol, BRow, BContainer } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BContainer,
  },
  computed: {
    getStorageEntries() {
      return this.$store.getters.storageEntries
    },
  },
  methods: {
    createBooking() {
      if (this.getStorageEntries.length === 0) {
        this.showNoStorageSweetAlert()
      } else {
        this.$router.push({ path: '/booking' })
      }
    },
    createOffer() {
      if (this.getStorageEntries.length === 0) {
        this.showNoStorageSweetAlert()
      } else {
        this.$router.push({ path: '/create-offer' })
      }
    },

    showNoStorageSweetAlert() {
      this.$swal({
        title: 'Lager erforderlich',
        text: 'Es muss mindestens Lager angelegt sein, bevor eine Buchung erstellt werden kann.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
