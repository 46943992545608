import customersAndOrders from './customers-and-orders'
import dashboard from './dashboards'
import developer from './developer'
import feedback from './feedback'
import inquiriesAndOffers from './inquiries-and-offers'
import links from './links'
import settings from './settings'
import shop from './shop'
import storagesAndLocations from './storages-and-locations'

export default [
  ...dashboard,
  ...inquiriesAndOffers,
  ...customersAndOrders,
  ...storagesAndLocations,
  ...shop,
  ...settings,
  ...feedback,
  ...links,
  ...developer,
]
